var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _c(
        "b-form",
        { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
        [
          _c(
            "b-card",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "10" } },
                    [
                      _c(
                        "form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Business Type:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "oilType",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "oilType",
                                  options: _vm.types.oilType,
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please enter');"
                                },
                                model: {
                                  value: _vm.form.oilType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "oilType", $$v)
                                  },
                                  expression: "form.oilType"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.form.oilType == 1
                        ? _c(
                            "form-row",
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "right",
                                    fn: function() {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "text-align": "center",
                                              "margin-bottom": "1em"
                                            }
                                          },
                                          [_vm._v("%")]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                1329510802
                              )
                            },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Nic Ingredients:",
                                    "label-cols-lg": "3",
                                    "label-align-lg": "right",
                                    "label-for": "nicIngredients",
                                    "label-class": "requiredRow"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "nicIngredients",
                                      trim: "",
                                      required: "",
                                      oninvalid:
                                        "setCustomValidity('Please enter only numbers and a decimal point.');",
                                      oninput: "setCustomValidity('');",
                                      pattern: "^\\d*\\.?\\d*$"
                                    },
                                    model: {
                                      value: _vm.form.nicIngredients,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "nicIngredients",
                                          $$v
                                        )
                                      },
                                      expression: "form.nicIngredients"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Product Type:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "productType",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "productType",
                                  options: _vm.types.productType,
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please enter');"
                                },
                                model: {
                                  value: _vm.form.type,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "type", $$v)
                                  },
                                  expression: "form.type"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Activation Type:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "activationType",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "activationType",
                                  options: _vm.types.activationType,
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please enter');"
                                },
                                model: {
                                  value: _vm.form.activationType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "activationType", $$v)
                                  },
                                  expression: "form.activationType"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Curve Type:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "curveType",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "curveType",
                                  options: _vm.types.curveType,
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please enter');"
                                },
                                model: {
                                  value: _vm.form.curveType,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "curveType", $$v)
                                  },
                                  expression: "form.curveType"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "margin-bottom": "1em"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.form.productSeriesName.length
                                          ) +
                                          "/50 "
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Product Series Name:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "productSeriesName",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "productSeriesName",
                                  trim: "",
                                  required: "",
                                  maxlength: "50",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('');",
                                  placeholder: "Please enter"
                                },
                                model: {
                                  value: _vm.form.productSeriesName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "productSeriesName", $$v)
                                  },
                                  expression: "form.productSeriesName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "form-row",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "text-align": "center",
                                        "margin-bottom": "1em"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.form.remarks.length) +
                                          "/1000 "
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Product Series Introduction:",
                                "label-cols-lg": "3",
                                "label-align-sm": "right",
                                "label-for": "productSeriesIntroductio",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-textarea", {
                                attrs: {
                                  id: "remark",
                                  placeholder: "Please enter",
                                  rows: "6",
                                  maxlength: "1000",
                                  "max-rows": "6",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please enter');"
                                },
                                model: {
                                  value: _vm.form.remarks,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "remarks", $$v)
                                  },
                                  expression: "form.remarks"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "align-items-center" },
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c("div", { staticClass: "blockquote" }, [
                      _c("h4", [_vm._v("Specifications and Models:")])
                    ])
                  ])
                ],
                1
              ),
              _vm._l(_vm.form.specifications, function(item, key) {
                return _c(
                  "b-row",
                  { key: key },
                  [
                    _c(
                      "b-col",
                      { staticClass: "specification", attrs: { cols: "10" } },
                      [
                        _c(
                          "div",
                          { staticClass: "vo-column" },
                          [
                            _c(
                              "form-row",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Available Compatibility:",
                                      "label-cols-lg": "3",
                                      "label-align-lg": "right",
                                      "label-class": "requiredRow",
                                      "label-for": "availableCompatibilityId"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-form-radio",
                                      {
                                        staticClass:
                                          "custom-control-inline pt-2",
                                        attrs: { value: "1", required: "" },
                                        model: {
                                          value:
                                            _vm.form.specifications[key]
                                              .availableCompatibilityId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form.specifications[key],
                                              "availableCompatibilityId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.specifications[key].availableCompatibilityId"
                                        }
                                      },
                                      [_vm._v("OMNI NFC ")]
                                    ),
                                    _c(
                                      "b-form-radio",
                                      {
                                        staticClass:
                                          "custom-control-inline pt-2",
                                        attrs: { value: "2", required: "" },
                                        model: {
                                          value:
                                            _vm.form.specifications[key]
                                              .availableCompatibilityId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form.specifications[key],
                                              "availableCompatibilityId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.specifications[key].availableCompatibilityId"
                                        }
                                      },
                                      [_vm._v("OMNI Hub ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "form-row",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Available Tank Size:",
                                      "label-cols-lg": "3",
                                      "label-align-lg": "right",
                                      "label-class": "requiredRow",
                                      "label-for": "availableTankSize"
                                    }
                                  },
                                  _vm._l(_vm.types.availableTankSize, function(
                                    item,
                                    i
                                  ) {
                                    return _c(
                                      "b-form-radio",
                                      {
                                        key: i,
                                        staticClass:
                                          "custom-control-inline pt-2",
                                        attrs: { value: item.id, required: "" },
                                        model: {
                                          value:
                                            _vm.form.specifications[key]
                                              .taskCapacityId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form.specifications[key],
                                              "taskCapacityId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.specifications[key].taskCapacityId"
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(item.value) + " ")]
                                    )
                                  }),
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "form-row",
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "right",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center",
                                                "margin-bottom": "1em"
                                              }
                                            },
                                            [_vm._v("Ω")]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Resistance Value:",
                                      "label-cols-lg": "3",
                                      "label-align-lg": "right",
                                      "label-for": "resistanceValue",
                                      "label-class": "requiredRow"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "productSeriesName",
                                        trim: "",
                                        required: "",
                                        oninvalid:
                                          "setCustomValidity('Please enter only numbers and a decimal point.');",
                                        oninput: "setCustomValidity('');",
                                        pattern: "^\\d*\\.?\\d*$"
                                      },
                                      model: {
                                        value:
                                          _vm.form.specifications[key]
                                            .resistance,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.specifications[key],
                                            "resistance",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.specifications[key].resistance"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "form-row",
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "right",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center",
                                                "margin-bottom": "1em"
                                              }
                                            },
                                            [_vm._v("V")]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Max Output Voltage:",
                                      "label-cols-lg": "3",
                                      "label-align-lg": "right",
                                      "label-for": "maxOutputVoltage",
                                      "label-class": "requiredRow"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "maxOutputVoltage",
                                        trim: "",
                                        required: "",
                                        oninvalid:
                                          "setCustomValidity('Please enter only numbers and a decimal point.');",
                                        oninput: "setCustomValidity('');",
                                        pattern: "^\\d*\\.?\\d*$"
                                      },
                                      model: {
                                        value:
                                          _vm.form.specifications[key]
                                            .maxOutputVoltage,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.specifications[key],
                                            "maxOutputVoltage",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.specifications[key].maxOutputVoltage"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "form-row",
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "right",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center",
                                                "margin-bottom": "1em"
                                              }
                                            },
                                            [
                                              _vm._v(" Please go to the "),
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "custom-color",
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: _vm.clickPublicInfo
                                                  }
                                                },
                                                [_vm._v("Public Info Mgmt")]
                                              ),
                                              _vm._v(
                                                " page to create new option "
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Other Option:",
                                      "label-cols-lg": "3",
                                      "label-align-lg": "right",
                                      "label-for": "otherOptionId",
                                      "label-class": "requiredRow"
                                    }
                                  },
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        id: "otherOptionId",
                                        options: _vm.types.otherOption,
                                        required: "",
                                        oninvalid:
                                          "setCustomValidity('Please enter');"
                                      },
                                      model: {
                                        value:
                                          _vm.form.specifications[key]
                                            .otherOptionId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.specifications[key],
                                            "otherOptionId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.specifications[key].otherOptionId"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "form-row",
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "right",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center",
                                                "margin-bottom": "1em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.modelName.length
                                                  ) +
                                                  "/50 "
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Product Model #:",
                                      "label-cols-lg": "3",
                                      "label-align-lg": "right",
                                      "label-for": "productSeriesName",
                                      "label-class": "requiredRow"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "productSeriesName",
                                        trim: "",
                                        required: "",
                                        maxlength: "50",
                                        oninvalid:
                                          "setCustomValidity('Please enter');",
                                        oninput: "setCustomValidity('');",
                                        placeholder: "Please enter"
                                      },
                                      model: {
                                        value:
                                          _vm.form.specifications[key]
                                            .modelName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.specifications[key],
                                            "modelName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.specifications[key].modelName"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "form-row",
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "right",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center",
                                                "margin-bottom": "1em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.variantCode.length
                                                  ) +
                                                  "/50 "
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Variant Code #:",
                                      "label-cols-lg": "3",
                                      "label-align-lg": "right",
                                      "label-for": "variantCode",
                                      "label-class": "requiredRow"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "variantCode",
                                        trim: "",
                                        required: "",
                                        maxlength: "50",
                                        oninvalid:
                                          "setCustomValidity('Please enter');",
                                        oninput: "setCustomValidity('');",
                                        placeholder: "Please enter"
                                      },
                                      model: {
                                        value:
                                          _vm.form.specifications[key]
                                            .variantCode,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.specifications[key],
                                            "variantCode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.specifications[key].variantCode"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "form-row",
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "right",
                                      fn: function() {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center",
                                                "margin-bottom": "1em"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item.deviceName.length
                                                  ) +
                                                  "/50 "
                                              )
                                            ]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Marketing Name:",
                                      "label-cols-lg": "3",
                                      "label-align-lg": "right",
                                      "label-for": "marketingName",
                                      "label-class": "requiredRow"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "marketingName",
                                        trim: "",
                                        required: "",
                                        maxlength: "50",
                                        oninvalid:
                                          "setCustomValidity('Please enter');",
                                        oninput: "setCustomValidity('');",
                                        placeholder: "Please enter"
                                      },
                                      model: {
                                        value:
                                          _vm.form.specifications[key]
                                            .deviceName,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form.specifications[key],
                                            "deviceName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.specifications[key].deviceName"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "form-row",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Product Picture:",
                                      "label-cols-lg": "3",
                                      "label-align-lg": "right",
                                      "label-for": "brand-logo",
                                      "label-class": "requiredRow"
                                    }
                                  },
                                  [
                                    _c("input", {
                                      staticClass: "filestyle",
                                      staticStyle: {
                                        position: "absolute",
                                        clip: "rect(0px, 0px, 0px, 0px)"
                                      },
                                      attrs: {
                                        type: "file",
                                        "data-input": "false",
                                        id: "filestyle" + key,
                                        tabindex: "-1",
                                        oninvalid:
                                          "setCustomValidity('Please upload');",
                                        oninput: "setCustomValidity('');",
                                        accept: "image/*",
                                        multiple: ""
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.upload($event, key)
                                        }
                                      }
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "bootstrap-filestyle input-group"
                                      },
                                      [
                                        _c("div", {
                                          staticStyle: {
                                            position: "absolute",
                                            width: "100%",
                                            height: "35px",
                                            "z-index": "-1"
                                          },
                                          attrs: { name: "filedrag" }
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "group-span-filestyle",
                                            attrs: { tabindex: "0" }
                                          },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "custom-width",
                                                attrs: { variant: "info" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.uploadFile(key)
                                                  }
                                                }
                                              },
                                              [_vm._v("upload picture")]
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                " Recommended size of picture:490 pixels * 490 pixels, supporting JPG and PNG formats "
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    item.productPicture
                                      ? _c(
                                          "b-row",
                                          { staticClass: "mt-4" },
                                          _vm._l(item.productPicture, function(
                                            pictureItem,
                                            pictureKey
                                          ) {
                                            return _c(
                                              "b-col",
                                              {
                                                key: pictureKey,
                                                attrs: { lg: "3" }
                                              },
                                              [
                                                _c("product-picture", {
                                                  attrs: { item: pictureItem },
                                                  on: {
                                                    deletePicture: function(
                                                      $event
                                                    ) {
                                                      return _vm.deletePicture(
                                                        pictureItem,
                                                        key
                                                      )
                                                    },
                                                    setCover: function($event) {
                                                      return _vm.setCover(
                                                        pictureItem,
                                                        key
                                                      )
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          }),
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "form-row",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Status:",
                                      "label-cols-lg": "3",
                                      "label-align-lg": "right",
                                      "label-class": "requiredRow"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-form-radio",
                                      {
                                        staticClass:
                                          "custom-control-inline pt-2",
                                        attrs: { value: "0", required: "" },
                                        model: {
                                          value:
                                            _vm.form.specifications[key].status,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form.specifications[key],
                                              "status",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.specifications[key].status"
                                        }
                                      },
                                      [_vm._v("Enable ")]
                                    ),
                                    _c(
                                      "b-form-radio",
                                      {
                                        staticClass:
                                          "custom-control-inline pt-2",
                                        attrs: { value: "1", required: "" },
                                        model: {
                                          value:
                                            _vm.form.specifications[key].status,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form.specifications[key],
                                              "status",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.specifications[key].status"
                                        }
                                      },
                                      [_vm._v("Disable ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _c("b-col", { attrs: { cols: "2" } }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "vo-column align-items-center h-100 justify-content-center ml-4",
                          staticStyle: { flex: "1" }
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "custom-width",
                              attrs: { variant: "info" },
                              on: {
                                click: function($event) {
                                  return _vm.addSpecification(key)
                                }
                              }
                            },
                            [_vm._v("+Add ")]
                          ),
                          (_vm.editId > 0 &&
                            key > 0 &&
                            _vm.form.specifications[key].removeStatus) ||
                          (!_vm.editId && key > 0)
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "custom-width mt-1",
                                  attrs: { variant: "info" },
                                  on: {
                                    click: function($event) {
                                      return _vm.deleteSpecification(key, item)
                                    }
                                  }
                                },
                                [_vm._v("-Delete ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              })
            ],
            2
          ),
          _c(
            "b-form-group",
            { attrs: { "label-cols-lg": "3" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "custom-width",
                  attrs: { type: "submit", variant: "primary" }
                },
                [_vm._v("Save")]
              ),
              _c(
                "b-button",
                { staticClass: "ml-5 custom-width", attrs: { type: "reset" } },
                [_vm._v("Cancel ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Dialog",
        {
          attrs: { imageSrc: require("@/assets/success.png"), noBtn: "OK" },
          on: { cancel: _vm.cancel, hide: _vm.hide },
          model: {
            value: _vm.successAlear,
            callback: function($$v) {
              _vm.successAlear = $$v
            },
            expression: "successAlear"
          }
        },
        [_vm._v(" Saved successfully ")]
      ),
      _c(
        "Dialog",
        {
          attrs: { imageSrc: require("@/assets/confirm.svg"), noBtn: "OK" },
          on: { cancel: _vm.cancel1, hide: _vm.hide1 },
          model: {
            value: _vm.errorAlear,
            callback: function($$v) {
              _vm.errorAlear = $$v
            },
            expression: "errorAlear"
          }
        },
        [_vm._v(" " + _vm._s(_vm.errorContent) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }